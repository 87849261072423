import { ActionFunction, ActionFunctionArgs, LoaderFunction } from '@remix-run/node';
import { SubscriptionUseFrontendTypes } from '~/types/log';
import { getUsersSubscriptions } from '~/utils/apis/inventory';
import { logSubscriptionUse } from '~/utils/apis/log';
import { errorLogger } from '~/utils/logger.server';

export enum LogAction {
    Log = 'LOG_SECURITY',
    LogSubscription = 'LOG_SUBSCRIPTION',
}

export enum LogTopic {
    Basic = '[Security]',
    Copyright = '[Security - Copyright]',
}

export const loader: LoaderFunction = async () => {
    throw new Response('Not Found', { status: 404 });
};

export const action: ActionFunction = async ({ request }: ActionFunctionArgs) => {
    try {
        const data = await request.json();

        if (!data.payload) {
            errorLogger.warn(`${LogTopic.Basic}: Log event was received without payload. Status 400.}`);
            return { success: true };
        }

        if (data.action === LogAction.Log) {
            errorLogger.warn(`${data.payload.type}: ${data.payload.message}`);
            return { success: true };
        }

        if (data.action === LogAction.LogSubscription) {
            const usersSubscriptionsResponse = await getUsersSubscriptions(request);
            if (!usersSubscriptionsResponse.success || !usersSubscriptionsResponse.data) {
                errorLogger.error(
                    `Fetching user subscriptions for subscription use log failed. Status ${usersSubscriptionsResponse.status}.`,
                    usersSubscriptionsResponse,
                );
                return { success: true };
            }

            const subscription = usersSubscriptionsResponse.data?.items?.filter(
                (item) => !item.expires || new Date(item.expires) > new Date(),
            );

            if (!Array.isArray(subscription) || subscription.length === 0) {
                errorLogger.error(
                    'Logging subscription use failed because no active subscription was found.',
                    usersSubscriptionsResponse,
                );
                return { success: true };
            }

            const response = await logSubscriptionUse(request, {
                ...(data.payload as SubscriptionUseFrontendTypes),
                subscription_plan_id: subscription[0].plan_id,
                total_events: 1,
                message: '',
            });
            if (!response.success) {
                errorLogger.error(`Logging subscription use failed. Status ${response.status}.`, response);
                return { success: true };
            }
            return { success: true };
        }

        errorLogger.warn(`${LogTopic.Basic}: Log event but did not include a valid action.`);
        return { success: true };
    } catch (error) {
        errorLogger.error('Unspecified error logging event.', error);
        return { success: true };
    }
};
